import React, { useState } from 'react';
// import mainPic from './Website Photo.png';
// import hiltonPic from './hilton.jpg';
// import terracePic from './terrace.jpg';
// import schedulePic from './schedule2.jpg';
import './App.css';
import { createTheme, PaletteColorOptions, ThemeProvider, Typography, Grid, Button, AppBar, Toolbar, IconButton, Drawer, Box, Divider, ListItemButton, ListItemText, List, ListItem, Collapse, TableRow } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Item from '@mui/material/Grid';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

declare module '@mui/material/styles' {
    interface CustomPalette {
      steelBlue: PaletteColorOptions;
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
  }
  
  declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      steelBlue: true;
    }
  }

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDp7v8ytCIsrtseiClxQdFp05Zkha5mbz0",
  authDomain: "wedding-website-8437e.firebaseapp.com",
  projectId: "wedding-website-8437e",
  storageBucket: "wedding-website-8437e.appspot.com",
  messagingSenderId: "130520392374",
  appId: "1:130520392374:web:128e7ebc81f51eccf8bc17",
  measurementId: "G-3LK42HMCYT"
};


const columns: GridColDef[] = [
    {
      field: 'table',
      headerName: 'Table #',
      type: 'number',
      width: 150
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      width: 250
    },
  ];
  
  const rows = [
    { id: 159, table: 1, name: 'Joshua Stephani' },
    { id: 160, table: 1, name: 'Ana Hoeft' },
    { id: 161, table: 1, name: 'Ben Giese' },
    { id: 162, table: 1, name: 'Sage Erdman' },
    { id: 163, table: 1, name: 'Allison Morgano' },
    { id: 164, table: 1, name: 'John Kruse' },
    { id: 165, table: 1, name: 'Megan Socha' },
    { id: 166, table: 1, name: 'Peter Kor' },
    { id: 167, table: 1, name: 'Hunter Kor' },
    { id: 168, table: 1, name: 'Aliya Stuehn' },
    { id: 169, table: 1, name: 'Casey Michelic' },
    { id: 170, table: 1, name: 'Damek Wellington' },
    { id: 171, table: 1, name: 'Denae Stern' },
    { id: 172, table: 1, name: 'Kassi Nacopoulos' },
    { id: 173, table: 1, name: 'Bennett Hoeft' },
    { id: 174, table: 1, name: 'Elliott Jang' },
    { id: 175, table: 1, name: 'Jessica Stephani' },
    { id: 176, table: 1, name: 'Bonnie Hoeft' },
    { id: 177, table: 1, name: 'John Hoeft' },
    { id: 178, table: 1, name: 'Ken Stephani' },
    { id: 179, table: 1, name: 'Sue Stephani' },
    { id: 0, table: 17, name: 'Ryan Mattek' },
    { id: 1, table: 17, name: 'Matthew Pucker' },
    { id: 2, table: 17, name: 'Brian Hoeft' },
    { id: 3, table: 17, name: 'Laureen Hoeft' },
    { id: 4, table: 17, name: 'Pete Hoeft' },
    { id: 5, table: 17, name: 'Lindsey Hoeft' },
    { id: 6, table: 17, name: 'Michael Sterchak' },
    { id: 7, table: 17, name: 'Meryl Hoeft' },
    { id: 8, table: 5, name: 'Janis Roesch' },
    { id: 9, table: 5, name: 'LuAnn Juran' },
    { id: 10, table: 5, name: 'Matthew Juran' },
    { id: 11, table: 5, name: 'Tim Juran' },
    { id: 12, table: 5, name: 'Mat Johnson' },
    { id: 13, table: 5, name: 'Jeanna Johnson' },
    { id: 14, table: 5, name: 'Sue Mayne' },
    { id: 15, table: 5, name: 'Rick Mayne' },
    { id: 16, table: 4, name: 'Jess Green' },
    { id: 17, table: 4, name: 'Steve Green' },
    { id: 18, table: 4, name: 'Melissa Wiest ' },
    { id: 19, table: 4, name: 'Mo Wiest' },
    { id: 20, table: 4, name: 'Eric Mayne' },
    { id: 21, table: 4, name: 'Nichole Mayne' },
    { id: 22, table: 4, name: 'Sean Tepper' },
    { id: 23, table: 4, name: 'Jamie Udelhofen' },
    { id: 24, table: 2, name: 'Beth Huff' },
    { id: 25, table: 2, name: 'Austin Huff' },
    { id: 26, table: 2, name: 'Megan Roesch' },
    { id: 27, table: 2, name: 'Tim Pergande' },
    { id: 28, table: 2, name: 'Mikayla Wedige' },
    { id: 29, table: 2, name: 'Kyle Roesch' },
    { id: 30, table: 2, name: 'Kari Yoose' },
    { id: 31, table: 2, name: 'Dan Yoose' },
    { id: 32, table: 12, name: 'Karen Engels' },
    { id: 33, table: 12, name: 'Jim Engels' },
    { id: 34, table: 12, name: 'Nick Engels' },
    { id: 35, table: 12, name: 'Kyle Engels' },
    { id: 36, table: 12, name: 'McKenzie Schmitz' },
    { id: 37, table: 12, name: 'Tamara Schmitz' },
    { id: 38, table: 12, name: 'Cate Barnett' },
    { id: 39, table: 12, name: 'Ted Barnett' },
    { id: 40, table: 9, name: 'Craig Moldenhauer' },
    { id: 41, table: 9, name: 'Jill Moldenhauer' },
    { id: 42, table: 9, name: 'DuWayne Jahnke' },
    { id: 43, table: 9, name: 'Sandy Jahnke' },
    { id: 44, table: 9, name: 'Pete Blum' },
    { id: 45, table: 9, name: 'Joan Blum' },
    { id: 46, table: 9, name: 'Janis Sigmund' },
    { id: 47, table: 9, name: 'Tom Sigmund' },
    { id: 48, table: 10, name: 'Keelin Pergande' },
    { id: 49, table: 10, name: 'Paige Schibline' },
    { id: 50, table: 10, name: 'Trevor Schibline' },
    { id: 51, table: 10, name: 'Grant Pergande' },
    { id: 52, table: 10, name: 'Cody Hoeft' },
    { id: 53, table: 10, name: 'Esme Johnson' },
    { id: 54, table: 10, name: 'Joy Kirkland' },
    { id: 55, table: 11, name: 'Sally Hoeft' },
    { id: 56, table: 11, name: 'Harv Mathias' },
    { id: 57, table: 11, name: 'Bill Hoeft' },
    { id: 58, table: 11, name: 'Rich Pergande' },
    { id: 59, table: 11, name: 'Jodi Pergande' },
    { id: 60, table: 11, name: 'Bob Hoeft' },
    { id: 61, table: 11, name: 'Rhoda Hoeft' },
    { id: 180, table: 8, name: 'Robert Giese' },
    { id: 181, table: 8, name: 'Debra Giese' },
    { id: 62, table: 18, name: 'Klaus Lorenz' },
    { id: 63, table: 18, name: 'Vincent Lorenz' },
    { id: 64, table: 18, name: 'Carson Blaszak' },
    { id: 65, table: 18, name: 'Maddie Kohnke' },
    { id: 66, table: 18, name: 'Wendy Sievert' },
    { id: 67, table: 18, name: 'Scott Sievert' },
    { id: 68, table: 18, name: 'Karen Rich' },
    { id: 69, table: 18, name: 'Jeff Rich' },
    { id: 70, table: 3, name: 'Adam Pesich' },
    { id: 71, table: 3, name: 'Barb Pesich' },
    { id: 72, table: 3, name: 'Caitlin Hallada' },
    { id: 73, table: 3, name: 'Elizabeth Malone' },
    { id: 74, table: 3, name: 'Paul Jahnke' },
    { id: 75, table: 3, name: 'Christine Eaton' },
    { id: 76, table: 3, name: 'Julie Knief' },
    { id: 77, table: 3, name: 'Aaron Knief' },
    { id: 78, table: 6, name: 'Jack Kiesow' },
    { id: 79, table: 6, name: 'Henry Wieland' },
    { id: 80, table: 6, name: 'Peyton Frohna' },
    { id: 81, table: 6, name: 'Cameron McDowell ' },
    { id: 82, table: 6, name: 'Erin Zajc' },
    { id: 83, table: 6, name: 'Rae Slager' },
    { id: 84, table: 6, name: 'Shane Deng' },
    { id: 85, table: 6, name: 'Alec Nicolaysen' },
    { id: 86, table: 6, name: 'John Kuehl' },
    { id: 87, table: 7, name: 'Jennifer Stephani' },
    { id: 88, table: 7, name: 'Brian Krejcarek' },
    { id: 89, table: 7, name: 'Justin Stephani' },
    { id: 90, table: 7, name: 'Emma Roller' },
    { id: 91, table: 7, name: 'Tony Bartman' },
    { id: 92, table: 7, name: 'Tracy Stephani' },
    { id: 93, table: 7, name: 'Kurt Jenschke' },
    { id: 94, table: 7, name: 'Linda Jenschke' },
    { id: 95, table: 8, name: 'Sandra Huyser' },
    { id: 96, table: 8, name: 'Jane Stephani' },
    { id: 97, table: 8, name: 'Shawn Moede' },
    { id: 98, table: 8, name: 'Erin Moede' },
    { id: 99, table: 8, name: 'Sara Leak' },
    { id: 100, table: 8, name: 'Mike Stephani' },
    { id: 101, table: 8, name: 'Mary Jo Stephani' },
    { id: 104, table: 16, name: 'Mike Wendt' },
    { id: 105, table: 16, name: 'Mary Wendt' },
    { id: 106, table: 16, name: 'Thomas Wendt' },
    { id: 107, table: 16, name: 'Roxanne Wendt' },
    { id: 108, table: 16, name: 'Bill Allen' },
    { id: 109, table: 16, name: 'Judy Allen' },
    { id: 110, table: 16, name: 'Dale Van Groll' },
    { id: 111, table: 16, name: 'Bobbi Van Groll' },
    { id: 112, table: 13, name: 'Sheryl Preissner' },
    { id: 113, table: 13, name: 'Victoria Werner' },
    { id: 114, table: 13, name: 'Sandy Klein' },
    { id: 115, table: 13, name: 'Hope Feyen' },
    { id: 116, table: 13, name: 'Casandra Witt' },
    { id: 117, table: 13, name: 'Brody Preissner' },
    { id: 118, table: 13, name: 'Kaylee Deich' },
    { id: 120, table: 14, name: 'Steve Klein' },
    { id: 121, table: 14, name: 'Shelly Klein' },
    { id: 122, table: 14, name: 'Matt Klein' },
    { id: 123, table: 14, name: 'Andrea Schmitz' },
    { id: 124, table: 14, name: 'Scott Klein' },
    { id: 125, table: 14, name: 'Heather Klein' },
    { id: 126, table: 14, name: 'Emily Klein' },
    { id: 127, table: 15, name: 'Gary Kuehl' },
    { id: 128, table: 15, name: 'Calla Kuehl' },
    { id: 129, table: 15, name: 'Helen Jang' },
    { id: 130, table: 15, name: 'Jae Jang' },
    { id: 131, table: 15, name: 'Al Hoerth' },
    { id: 132, table: 15, name: 'Donna Hoerth' },
    { id: 133, table: 15, name: 'Kevin Redeker' },
    { id: 134, table: 15, name: 'Christine Redeker' },
    { id: 135, table: 19, name: 'Jim Blasco' },
    { id: 136, table: 19, name: 'Jennifer Blasco' },
    { id: 137, table: 19, name: 'Aline Mastelic' },
    { id: 138, table: 19, name: 'Benedetta Marrocco' },
    { id: 139, table: 19, name: 'Jamie Linford' },
    { id: 140, table: 19, name: 'Claire Linford' },
    { id: 141, table: 19, name: 'Michael Westbrook' },
    { id: 142, table: 19, name: 'Emily Romuald' },
    { id: 143, table: 20, name: 'Jim Haltaufderheide' },
    { id: 144, table: 20, name: 'Molly Haltaufderheide' },
    { id: 145, table: 20, name: 'Scott Ohlrich' },
    { id: 146, table: 20, name: 'Pam Ohlrich' },
    { id: 147, table: 20, name: 'Steven Wonser' },
    { id: 148, table: 20, name: 'Wendy Wonser' },
    { id: 149, table: 20, name: 'Dave Kimball' },
    { id: 150, table: 20, name: 'Tracy Kimball' },
    { id: 151, table: 21, name: 'Don Toshner' },
    { id: 152, table: 21, name: 'Kay Toshner' },
    { id: 153, table: 21, name: 'Dan Schneider' },
    { id: 154, table: 21, name: 'Sherry Schneider' },
    { id: 155, table: 21, name: 'Mike Baumhardt' },
    { id: 156, table: 21, name: 'Sue Baumhardt' },
    { id: 157, table: 21, name: 'Tim Deacy' },
    { id: 158, table: 21, name: 'Anne Deacy' },
  ];


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });
const analytics = getAnalytics(app);
const theme = createTheme({
    typography: {
        fontFamily: [
            'Playfair Display',
            'Great Vibes',
            'Satisfy'
        ].join(','),
        h2: {
            fontSize: '2.5rem'
        }
    },
    palette: {
        steelBlue: createColor("#677dbf")
    }
}); 

const faqs = [["What is the dress code?", "Semi-formal. Please, do not wear jeans or shorts. Please also keep in mind that the ceremony and cocktail hour will be outside on a concrete patio."],
["Will the wedding be outdoors?", "Yes (weather pending), the ceremony and cocktail hour will be on the Monona Terrace rooftop. The rest of the night will be indoors. The weather backup space for the ceremony and cocktail hour will be at the same location, but indoors."],
["Can I take and share pictures?", "During the ceremony, please do not take any pictures. After that, please take plenty of pictures! We will have a Google photos album set up so everyone can share the pics from the night."],
["Are children allowed?", "We love your little ones! However, we have decided to keep our wedding and reception adults-only (18+)."],
["Can I bring a +1?", "We want to keep our wedding limited to our friends and family, so no +1s. Everyone invited will be noted on the invitation."],
["Are there any events the night before?", "Anyone is welcome to join us for drinks at The Great Dane after our rehearsal dinner (around 8pm). Otherwise, take some time to explore downtown Madison! Please note the rehearsal dinner itself will be for immediate family and the wedding party only."],
["Is the venue wheelchair accessible?", "Yes, please see the travel page for details on disabled parking and elevator access."],
["What will be served during cocktail hour?", "There will be an assortment of appetizers as well as an open bar."],
["Who should we contact with questions?", "Text Josh at 920-904-0106"]]

export enum Pages {
    Home,
    Party,
    Schedule,
    Travel,
    Photos,
    Registry,
    Story,
    Faqs,
    Rsvp,
    Tables
}

const isMobile = window.screen.width < 600;
const location = window.location.href.split("/").pop();

function FaqCollapse(props: {details: Array<string>}){
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <Divider />
            <ListItemButton style={{backgroundColor: '#282c34', color: 'white'}} onClick={() => setOpen(!open)}/* component="a" href={thread.data.url}*/ >
                <ListItemText primary={props.details[0]} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
                <p style={{padding: '20px'}}>{props.details[1]}</p>
            </Collapse>
            <Divider />
        </React.Fragment>
    )
}

class App extends React.Component {
    
    state = {
        menu: false,
        page: Pages.Home
    };

    componentDidMount(): void {
        switch (location) {
            case "travel":
                this.setState({page: Pages.Travel});
                break;
            case "party":
                this.setState({page: Pages.Party});
                break;
            case "story":
                this.setState({page: Pages.Story});
                break;
            case "schedule":
                this.setState({page: Pages.Rsvp});
                break;
            case "registry":
                this.setState({page: Pages.Registry});
                break;
            case "faqs":
                this.setState({page: Pages.Faqs});
                break;
            case "rsvp":
                this.setState({page: Pages.Rsvp});
                break;
            case "tables":
                this.setState({page: Pages.Tables});
                break;
            default:
                this.setState({page: Pages.Home});
                break;
        }
    }

    render() {
        return (
        <div className={`App ${this.state.page == Pages.Home ? "Home-page" : ""}`}>
            <ThemeProvider theme={theme}>
                {!isMobile ? <header className="App-header">
                    <Typography fontFamily={'Great Vibes'} variant='h2'>Josh and Ana</Typography>
                </header> : <></>}

                {!isMobile ? <div className={`Menu-bar ${this.state.page == Pages.Home ? "Home-menu" : ""}`}>
                    <Button onClick={() => {window.history.replaceState(null, "", "/"); this.setState({page: Pages.Home})}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Home</Typography></Button>
                    {/* <Button onClick={() => {window.history.replaceState(null, "", "/travel"); this.setState({ page: Pages.Travel });}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Travel</Typography></Button> */}
                    <Button onClick={() => {window.history.replaceState(null, "", "/party"); this.setState({page: Pages.Party})}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Wedding Party</Typography></Button>
                    <Button onClick={() => {window.history.replaceState(null, "", "/story"); this.setState({ page: Pages.Story })}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Our Story</Typography></Button>
                    {/* <Button onClick={() => {window.history.replaceState(null, "", "/schedule"); this.setState({page: Pages.Schedule})}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Schedule</Typography></Button> */}
                    <Button onClick={() => {window.history.replaceState(null, "", "/");this.setState({page: Pages.Photos, menu: false})}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Photos</Typography></Button>
                    <Button onClick={() => {window.history.replaceState(null, "", "/registry"); this.setState({ page: Pages.Registry })}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Registry</Typography></Button>
                    {/* <Button onClick={() => {window.history.replaceState(null, "", "/faqs"); this.setState({ page: Pages.Faqs })}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>FAQs</Typography></Button> */}
                    {/* <Button onClick={() => {window.history.replaceState(null, "", "/rsvp"); this.setState({ page: Pages.Rsvp })}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>RSVP</Typography></Button> */}
                    {/* <Button onClick={() => {window.history.replaceState(null, "", "/tables"); this.setState({ page: Pages.Tables })}}><Typography textTransform={'none'} fontFamily={'Playfair Display'}>Tables</Typography></Button> */}
                </div> : <></>}
            
            {isMobile ? <AppBar style={{backgroundColor: "#282c34"}} position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => this.setState({ menu: true })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography flexGrow={1} fontFamily={'Great Vibes'} variant='h2'>Josh and Ana</Typography>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => this.setState({ menu: false })}
                        style={{width: '48px', height: '48px'}}
                    >
                    </IconButton>
                </Toolbar>

                <Drawer
                    //from which side the drawer slides in
                    anchor="left"
                    //if open is true --> drawer is shown
                    open={this.state.menu}
                    //function that is called when the drawer should close
                    onClose={() => this.setState({menu: false})}
                >
                    <Box className={'Side-menu'}>
                        <IconButton color="inherit" sx={{ mb: 2 }}>
                            <CloseIcon onClick={() => this.setState({ menu: false })} />
                        </IconButton>

                        <Divider sx={{ mb: 2 }} />

                        <Box sx={{ mb: 2 }}>
                            <ListItemButton onClick={() => {window.history.replaceState(null, "", "/Home"); this.setState({page: Pages.Home, menu: false })}}>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                            {/* <ListItemButton onClick={() => {window.history.replaceState(null, "", "/travel"); this.setState({ page: Pages.Travel, menu: false });}}>
                                <ListItemText primary="Travel" />
                            </ListItemButton> */}
                            <ListItemButton onClick={() => {window.history.replaceState(null, "", "/party"); this.setState({page: Pages.Party, menu: false })}}>
                                <ListItemText primary="Wedding Party" />
                            </ListItemButton>
                            <ListItemButton onClick={() => {window.history.replaceState(null, "", "/story"); this.setState({ page: Pages.Story, menu: false })}}>
                                <ListItemText primary="Our Story" />
                            </ListItemButton>
                            {/* <ListItemButton onClick={() => this.setState({page: Pages.Schedule, menu: false})}>
                                <ListItemText primary="Schedule" />
                            </ListItemButton> */}
                            <ListItemButton onClick={() => {window.history.replaceState(null, "", "/");this.setState({page: Pages.Photos, menu: false})}}>
                                <ListItemText primary="Photos" />
                            </ListItemButton>
                            <ListItemButton onClick={() => {window.history.replaceState(null, "", "/registry"); this.setState({ page: Pages.Registry, menu: false })}}>
                                <ListItemText primary="Registry" />
                            </ListItemButton>
                            {/* <ListItemButton onClick={() => {window.history.replaceState(null, "", "/faqs"); this.setState({ page: Pages.Faqs, menu: false })}}>
                                <ListItemText primary="FAQs" />
                            </ListItemButton> */}
                            {/* <ListItemButton onClick={() => {window.history.replaceState(null, "", "/rsvp"); this.setState({ page: Pages.Rsvp, menu: false })}}>
                                <ListItemText primary="RSVP" />
                            </ListItemButton> */}
                            {/* <ListItemButton onClick={() => {window.history.replaceState(null, "", "/tables"); this.setState({ page: Pages.Tables, menu: false })}}>
                                <ListItemText primary="Table Numbers" />
                            </ListItemButton> */}
                        </Box>
                    </Box>
                </Drawer>
            </AppBar> : <></>}
                
            <div className='Menu-bar'>
                {/* <h1>The weather looks great today, so we will be on the rooftop, as planned!</h1> */}
                {/* <h1>Unfortunately, the weather looks poor today, so we will be inside for the day.</h1> */}
            </div>

            {/* pages */}
            {this.state.page == Pages.Home ? <div>
                <div className='Photo-container'>
                    {isMobile ? <img className="Photo-header-mobile" src={'https://lh3.googleusercontent.com/pw/AJFCJaVmERLYEFQLv7UM0tOY8czk6scanUyGZcsQWdwQEmV-tn7oET0TZ31Ot-mr81eoVx8njDzazHg776l7E1uCP09bgI7Qd56_K0MRtVHwhQmCUp-njlx2njI5jDIpa7pKoQvpIuOAVgcr661MPiHuY71kLg=w1801-h1287-s-no?authuser=0'} /> : <img className="Photo-header" src={'https://lh3.googleusercontent.com/pw/AJFCJaVmERLYEFQLv7UM0tOY8czk6scanUyGZcsQWdwQEmV-tn7oET0TZ31Ot-mr81eoVx8njDzazHg776l7E1uCP09bgI7Qd56_K0MRtVHwhQmCUp-njlx2njI5jDIpa7pKoQvpIuOAVgcr661MPiHuY71kLg=w1801-h1287-s-no?authuser=0'} />}
                </div>
            </div> : <></>}
            {this.state.page == Pages.Party ? <div>
                <hr />
                    <Typography fontFamily={'Great Vibes'} variant='h2'>Our Wedding Party</Typography>
                <hr />
                {!isMobile ? <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '10vh' }}
                >
                    <Grid item md={2}>
                        <Item>
                            <h3 className='Party-title'>Best Man</h3>
                            <p className='Party-name'>Ben Giese</p>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item>
                            <h3 className='Party-title'>Officiant</h3>
                            <p className='Party-name'>Mat Johnson</p>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item>
                            <h3 className='Party-title'>Maid of Honor</h3>
                            <p className='Party-name'>Sage Erdman</p>
                        </Item>
                    </Grid>
                </Grid> : <><Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '10vh' }}
                >
                    <Grid item xs={6}>
                        <Item>
                            <h3 className='Party-title'>Best Man</h3>
                            <p className='Party-name'>Ben Giese</p>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <h3 className='Party-title'>Maid of Honor</h3>
                            <p className='Party-name'>Sage Erdman</p>
                        </Item>
                    </Grid>
                </Grid><Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '10vh' }}
                >
                    <Grid item xs={4}>
                        <Item>
                            <h3 className='Party-title'>Officiant</h3>
                            <p className='Party-name'>Mat Johnson</p>
                        </Item>
                    </Grid>
                </Grid></>}
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '15vh' }}
                >
                    <Grid item xs={6} md={2}>
                        <Item>
                            <h3 className='Party-title'>Groomsmen</h3>
                            <p className='Party-name'>Peter Kor</p>
                            <p className='Party-name'>Casey Michelic</p>
                            <p className='Party-name'>Damek Wellington</p>
                            <p className='Party-name'>Bennett Hoeft</p>
                        </Item>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Item>
                            <h3 className='Party-title'>Bridesmaids</h3>
                            <p className='Party-name'>Allison Morgano</p>
                            <p className='Party-name'>Aliya Stuehn</p>
                            <p className='Party-name'>Denae Stern</p>
                            <p className='Party-name'>Jessica Stephani</p>
                        </Item>
                    </Grid> 
                </Grid> 
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: '2vh' }}
                >
                    <Grid item xs={6} md={2}>
                        <Item>
                            <h3 className='Party-title'>Ushers/Flower Men</h3>
                        </Item>
                    </Grid>
                </Grid> 
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={6} md={1}>
                        <Item>
                            <p className='Party-name'>Cam McDowell</p>
                        </Item>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Item>
                            <p className='Party-name'>Henry Wieland</p>
                        </Item>
                    </Grid>
                </Grid> 
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom={'150px'}
                >
                    <Grid item xs={12} md={8}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AP1GczM30iDTG4G03GUpLj6N47ThwMyHC36V70SaeMYIBNyG6nzb5xXKpvS5I3D4F0xG0IjmLwfOVJdmA13ye90m3uPl_HjgANLtbqIvYQty-0C1wFPvN63rr8ZeY5TDetK8BEF55xX4vPv_a4V7ShZ7Ec6oYA=w1029-h672-s-no-gm?authuser=0'}/>
                    </Grid>
                </Grid>
            </div> : <></>}
            {this.state.page == Pages.Schedule ? <div className='Page'>
                <hr />
                    <Typography fontFamily={'Great Vibes'} variant='h2'>Schedule</Typography>
                <hr />
                <img src={'https://lh3.googleusercontent.com/pw/AJFCJaW-Da1Lf0JujwY9PQalAHLNhgwdPjyrl2JYSvMNz6ggqCifoU7YOR_uCfI4xt86HbxHTJBN7GhX4BBU-JX2xU28Y6FmYO-lXGoIGxhKFGuit6BFPskyuBgi_4ngJRtpd8FHBTi8SFtC_3A95x_Rn8Rt5g=w304-h237-s-no?authuser=0'} />
                <h3>4:00 PM - Ceremony Starts</h3>
                <p>Please arrive by 3:45 PM, it takes a few minutes to get from the parking ramp to the rooftop.</p>
                <img src={'https://lh3.googleusercontent.com/pw/AJFCJaW-eYNm0_j1GfHuT-F8iq0j-i35foPeppZCtgmi7_j9aorIEZ8-9C3FQqOYEyK_9aTkulgdLBv_5kIzm_HCVxdZK3_SrsG7RQNWBoHhgIPDi2DQHaE487Ur4Fnmo7VIqQCZFIQAljgsFQ83B07X8CTIog=w289-h221-s-no?authuser=0'} />
                <h3>4:30 PM - Cocktail Hour</h3>
                <p>Cocktail hour will be on the rooftop immediately following the ceremony.</p>
                <img src={'https://lh3.googleusercontent.com/pw/AJFCJaWEXOsxjjqrKSXJqulSOc3cXhq6g0FENNGNWY24r5V_nyMdvz_BujK84ktkKaHguTqoG4ZKdmwGOpsi_MwFP-DeWiO0pIZLHdAw_OAB1gmlugQ-1GlVUASah-3R9onY4qtBXQ5tAzZIgxVe8j6l3LYfzw=w288-h218-s-no?authuser=0'} />
                <h3>6:00 PM - Dinner</h3>
                <img src={'https://lh3.googleusercontent.com/pw/AJFCJaWo_6GYxHZA2Jyk1k_pR4XdoHCmaocwWkwpDtzTSnpaR_fc54e9_bsI7Ij3vcUO9JIzNfSp4ycZQ5C897NmFcONKN8YHJ9FCM_fql3ftAOFIwR4oIU1wAnRK_VnU0Hl5FcWqmEBYVu-assuzIs7hYk__g=w310-h249-s-no?authuser=0'} />
                <h3>7:30 PM - Party!</h3>
                <img src={'https://lh3.googleusercontent.com/pw/AIL4fc9p8oHyGRrUHpXEhXcl892-6_DBIDkyJ_3KQFWXvVT5xMdfCo7L5OlwZxvf3dQ1W5u2NvCpRq1h-vIcXVV2A6vps8sAYbDzyxlf02QSJdFt8OFTkOllKbhG0tJEx1k6VEWt_5qiq1t7oYwOS81jebVIIQ=w249-h394-s-no?authuser=0'} />
                <h3 style={{paddingBottom: '150px'}}>Midnight - Happily Ever After!</h3>
                {/* <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaVeAmBS2LhzFPMvTfwyNA4qpUrh5X_1vWf2mEWqqC_N5sfnjp9cZM2FVD85YdMhugy6jZ_fG49-UVH13L7NM8CtlcpWWE7aQZ-uK55lUPly7S8-vu3PAdnLclk9XsQJXPWg-i-2MhO5GhD_BzxwKu3zxA=w479-h1041-s-no?authuser=0'} /> */}
            </div> : <></>}
            {this.state.page == Pages.Travel ? <div className='Page'>
                <hr />
                <Typography fontFamily={'Great Vibes'} variant='h2'>Travel</Typography>
                {/* <h3 className='Page-subtitle'>Our wedding will be at:</h3> */}
                <hr />
                <Button><a className='Venue-title' target="_blank" rel="noopener noreferrer" href="https://www.mononaterrace.com/"><Typography variant='h4' textTransform={'none'} fontFamily={'Great Vibes'}>The Monona Terrace</Typography></a></Button>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={4}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaW8FJAuby2uh2qgr3DQ92M8eXtxMJhm_K6oxQsG2M9NTVdmj-0wpc-FIMg1lmrCMzTWiDuLKP9z8QYBIAazXZLUAAn4W3IKNTkncIuB9YkzLrzD76-OqRZGUh1vRvC-cbViNiE1sue2yBwRRZ0YSOpNjQ=w600-h400-s-no?authuser=0'} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <h3 className='Page-subtitle'>Getting There</h3>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button className='Button' color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/mrRqmoKzstMtFwQM9"><Typography fontFamily={'Playfair Display'} variant='h6'>Monona Terrace Parking on Google Maps</Typography></Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={8} md={4}>
                        <p className='Content'>Enter the parking ramp at the button above or across the street from 102 W Wilson St. The ceremony is on the rooftop of the Monona Terrace.
                            Please head to the center of the building and go up the stairs/elevator to the 4th floor, then outside up the ramp to the rooftop. Additionally, there is an elevator to the
                            rooftop on the east side of the building. There will be staff and signage on site to assist.</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={8} md={4}>
                        <p className='Content'><b>Disabled parking and drop-off: </b>Disabled parking spaces are available for free. Enter across from 102 W Wilson St, follow the sign for "Drop off and additional parking"
                        (go up the ramp to the right of the garage entrance). Here you will be able to drop off anyone at the main entrance and/or park in a disabled parking space.</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={4}>
                        <Button className='Button' color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/zrmeP2iuS9a1otat5"><Typography fontFamily={'Playfair Display'} variant='h6'>Drop-off/Disabled Parking on Google Maps</Typography></Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={8} md={4}>
                        <p className='Content'>In the event of inclement weather, our backup space will be in Ballroom A on the 4th floor (will be clearly marked).</p>
                    </Grid>
                </Grid>
                <h3 className='Page-subtitle'>Hotels</h3>
                {/* <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '15vh' }}
                >
                    <Grid item xs={8} md={4}>
                        <p className='Content'>We have a block of rooms reserved for our guests at the 
                        Hilton Madison Monona Terrace. Please visit their site below to reserve your room. This hotel is directly connected to 
                        the Monona Terrace, so you don't even have to walk outside! Additionally, parking is available at the Monona Terrace for 
                        $5 for the whole weekend.</p> 
                    </Grid>
                </Grid> */}
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '15vh', marginBottom: "250px" }}
                >
                    <p className='Content'>Unfortunately, our block at the Hilton has filled up and the hotel has no more rooms available.</p>
                    <h3 style={{marginTop: '20px'}} className='Content'>Here are some more hotels near the venue:</h3>
                    {/* <Grid item xs={12} md={4}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaU1cRrOz_f_WmacS5_NtJPEODf-5KYD_qKx3QYa_TSXnmeoNs_lFhg7Va70hNVJybiVJit1b3VTT2nTQ5VRY_VUiY2K5EPnIqMbvSVik6kXNbmrv2VynfhiaII4BDgzPGFE3R-nrCW15-Q-PXgyu1mSWw=w700-h467-s-no?authuser=0'} />
                    </Grid> */}
                    { isMobile ? <iframe src="https://www.google.com/maps/d/embed?mid=1O1v-FpCINtP-c-5_fIrI0yCC6GDsHL0&hl=en&ehbc=2E312F" frameBorder='0' width={window.screen.width} height={window.screen.width}></iframe> : <iframe src="https://www.google.com/maps/d/embed?mid=1O1v-FpCINtP-c-5_fIrI0yCC6GDsHL0&hl=en&ehbc=2E312F" frameBorder='0' width="1200" height="800"></iframe>}
                </Grid>
                {/* <Button className='Button' color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://www.bestwestern.com/en_US/book/hotels-in-madison/best-western-premier-park-hotel/propertyCode.50061.html"><Typography fontFamily={'Playfair Display'} variant='h6'>Park Hotel</Typography></Button>
                <br />
                <Button className='Button' color='steelBlue' variant='contained' style={{marginTop: '20px', marginBottom: '60px', marginRight: '10px' }} target="_blank" rel="noopener noreferrer" href="https://www.theedgewater.com/?utm_source=2060digital&utm_medium=localseo&utm_campaign=gbp">
                    <Typography fontFamily={'Playfair Display'} variant='h6'>The Edgewater</Typography>
                </Button>
                <Button className='Button' color='steelBlue' variant='contained' style={{marginTop: '20px', marginBottom: '60px'}} target="_blank" rel="noopener noreferrer" href="https://www.ihg.com/holidayinnexpress/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qSlH=msnrr&qRms=2&qAdlt=1&qChld=0&qCiD=18&qCiMy=042024&qCoD=19&qCoMy=042024&qAAR=6CBARC&qRtP=6CBARC&setPMCookies=true&qSHBrC=EX&qDest=610%20John%20Nolen%20Drive,%20Madison,%20WI,%20US&qpMn=0&srb_u=1&qChAge=&qRmFltr=">
                    <Typography fontFamily={'Playfair Display'} variant='h6'>Holiday Inn Express</Typography>
                </Button> */}

            </div> : <></>}
            {this.state.page == Pages.Photos ? <div className='Page'>
                <Typography fontFamily={'Great Vibes'} variant='h2'>Photos</Typography>
                <div style={{ marginBottom: '20px'}}>
                    <Button className='Button' color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/PYd2WH2tdB9QwAZM7"><Typography fontFamily={'Playfair Display'} variant='h6'>Share your photos here!</Typography></Button>
                </div>
                <Button className='Button' color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/4X8ZjhJBczP6C4AT7"><Typography fontFamily={'Playfair Display'} variant='h6'>See our professional photos here!</Typography></Button>
            </div> : <></>}
            {this.state.page == Pages.Registry ? <div className='Page'>
                <hr />
                <Typography fontFamily={'Great Vibes'} variant='h2'>Registry</Typography>
                <hr />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '15vh' }}
                >
                    <Grid item xs={12} md={4} padding={1}>
                        {/* <Button sx={{ textTransform: 'unset', padding: "15px 40px", marginBottom: "30px" }} color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/wedding/share/hoeft-stephani">
                            <Typography fontFamily={'Satisfy'} variant='h4'>Amazon</Typography>
                        </Button> */}
                        <Button sx={{ textTransform: 'unset', padding: "15px 40px", marginRight: "40px", marginLeft: "40px", marginBottom: "30px" }} color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://www.crateandbarrel.com/gift-registry/ana-hoeft-and-joshua-stephani/r6837597">
                            <Typography fontFamily={'Satisfy'} variant='h4'>Crate & Barrel</Typography>
                        </Button>
                        <Button sx={{ textTransform: 'unset', padding: "15px 40px", marginBottom: "30px" }} color='steelBlue' variant='contained' target="_blank" rel="noopener noreferrer" href="https://www.target.com/gift-registry/gift/hoeft-stephani-wedding">
                            <Typography fontFamily={'Satisfy'} variant='h4'>Target</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography fontFamily={'Great Vibes'} variant='h2'>Honeymoon Fund:</Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} md={4}>
                            <p className='Content'>For our honeymoon, we are planning to visit Cinque Terre in Italy and Zermatt, Switzerland. Contribute to any of the following parts of our honeymoon below! (Each button goes to venmo, write 
                                the category in the venmo memo and we'll send you a picture of that category on our honeymoon!)
                            </p>
                        </Grid>
                    </Grid>
                    {/* First row */}
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '15vh' }}
                        md={6}
                    >
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc--HwHKvq5uniDwxHUnyYEg9y-wmSyr7RBBfMeCHnZX1JosJ_sS-BdSN2EasbJrav3DAqHfEvBn7-Yz79NiloD4ybbMPCBrj80FqCtSKM-k83iM2LefrLFPXYQ6Qu7E9zZjK0gkK4M6rfHfVTOufzCvEQ=w1006-h671-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>Hotel Fund</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc-8urzIADhhxA5XkQ8GGvI2qfy5X1mmIzzeh7IkPr8srhfgGx198O4j1TE6DnNuHWgg8XFRW76D6xMwBnmi-8OiLIJLAzrXV-j6fd_ToxNvlJx-r8ur9ABqu28Vl4Rk_0lyFu8xM0fJSYJc_pH4uhVhGA=w1200-h790-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>A day at the spa</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc_xsdsz3UMA1U265fXqUEGtwdq9ze_4UN9RlNDPW4xs3seI56qX3A_IFxAbQsLMPD42-3Rc423Oxrc1FwsrnF5JxFhqVlTE9VkpNOfdFXKwUtTjJLLdgHL9sbJkbpx0KIqKQ9vIfPhi4vi9XF8WtoeL0Q=w1200-h799-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>Buy us dinner</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* Second row */}
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '15vh' }}
                        md={6}
                    >
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc9mjnmlOeUc72CWrd-TX3RNFJTe6WM8ugU3e62UiPNBpGxrzV_DmdLSinWiygZXfWEcraoBXLxWXCjLGcpHtnHlJMeS53xzK22C1kwSXYwvlO-7EPw8Vz4gw0BU7Xm4ui6dtNWxnsfNoNM9FbNo9_HzPQ=w1080-h720-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>Flight Fund</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc9gw0bcF5s0kDmwQHmv36-v2tOXR5ddz6phqcS8TpE3_PEBYVzlb1wKXJ8BtvESsZXkLM-Gc0DlSuyTd6dQA-WXJNA2WlvmDV0zXAxseYExf0ceIiuLHNc3kbSCEBOeO2khTvQ59PmnOvOpqZ7izIAzOg=w1024-h682-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>Buy us a round</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{border: 1}} className={`${isMobile ? "Fund-box-mobile" : "Fund-box"}`}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '15vh', padding: '10px'}}
                                >
                                    <Grid item xs={12} md={4}>
                                        <img className='Photo-fund' src={"https://lh3.googleusercontent.com/pw/AIL4fc_d_p4ie336H47I6HGg75L5apKXJK7tGY_HYBnrCELPNtM7MAAIqpnBn-n5PfrgLes1eH3ts1KoXhPQmKBNgdJOM2KPw4SllzRYaws14eoAsjO8pjUogDmgJq6FKWgVOeSQsck0ltbV0s_Pmpaf6p5-IQ=w612-h408-s-no?authuser=0"}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className='Button Fund' variant='outlined' target="_blank" rel="noopener noreferrer" href="https://www.venmo.com/Josh-Stephani"><Typography fontFamily={'Satisfy'} variant='h5'>Train Fund</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid paddingTop={'25px'} paddingBottom={'50px'} item xs={12} md={4}>
                        <img className='Photo-venmo' src={'https://lh3.googleusercontent.com/pw/AIL4fc_8jfh-xfBDRK8ZYQGVLBM_qPGzGXUqrPfgXA9EpsNQRb9MpW8QhERmGNhnm4dGvVUaJio_LMYFfEbR4qUmBmviyqfTZZoeMoXghxptXtuSTTM0ReXoMICVdSw1y7SXbx8yTZ6KgRWEoWmOR1_vEN5w8w=w742-h861-s-no?authuser=0'}/>
                    </Grid>
                </Grid>
            </div> : <></>}
            {this.state.page == Pages.Story ? <div className='Page'>
                <hr />
                <Typography fontFamily={'Great Vibes'} variant='h2'>Our Story</Typography>
                <hr />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '15vh', paddingBottom: '150px' }}
                >
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>October 2018 - First Met</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Madison, WI-</p>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>August 11, 2020 - First Date</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Memorial Union Terrace-</p>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>August 22, 2020 - Officially Dating</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Green Lake Campground-</p>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaVwaGEsSt_fS9_hah76C_iCCKIqNWANjtzINeWsPeCnXPRyErFRc7dkaN9sVh7kIUFqIBm3hu-MA6_bHguE5mHmisqrLNidmr4lIc-5pV3Reh-Hll9aiYLWo1CCpFiepfnFPQ8TlV2dfle2-KuWa2ihNg=w1593-h976-s-no?authuser=0'} />
                    </Grid>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>May 20, 2021 - Moved in together</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Madison, WI-</p>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaWFBmPHOXTLMKOU0gc2aj5__VTjyLpcXLQfaIc4w51ZxE7NPJnnu-E2EtpyKabQBtrBWtmfQ7JuSZybrwhG7ShlBXZMfLsmuwm0F8cJkkClCWhDAX3NxsBPig8yvNbv23k74egxhLuhwYKwHzRgJq93vA=w1517-h936-s-no?authuser=0'} />
                    </Grid>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>June 10, 2022 - Engaged</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Vines and Rushes Winery-</p>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <img className='Photo' src={'https://lh3.googleusercontent.com/pw/AJFCJaXx9r5vcv3AviBSBVeWcTLUSb3zd7yrPFW-ULI69YFU-7ymeNQnEtuST-UqayvYiPlzCk-JLuAgrW0H07iu3wM8-Ok7F1PEwwLRTY-e5-fmWG4N_1lziP0-vNQ-moIB7K_gflse3sAlThAGzqX2t-zD_g=w1660-h775-s-no?authuser=0'} />
                    </Grid>
                    <Grid container direction="row" justifyContent="center" xs={12} md={4}>
                        <h3 className='Page-subtitle' style={{marginBottom: '0px'}}>May 18, 2024 - Married</h3>
                    </Grid>
                        <p style={{marginTop: '0px'}}>-Monona Terrace-</p>
                </Grid>
            </div> : <></>}
            {this.state.page == Pages.Faqs ? <div className='Page'>
                <hr />
                <Typography style={{paddingTop: '10px'}} fontFamily={'Great Vibes'} variant='h2'>FAQs</Typography>
                <hr />
                <div
                    style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }}
                >
                    <List sx={{width: isMobile ? '100%' : '50%'}}>
                        {
                            faqs.map((faq: any, index: any) =>
                                <FaqCollapse details={faq} />
                            )
                        }
                    </List>
                </div>
                
            </div> : <></>}
            {this.state.page == Pages.Rsvp ? <div className='Page'>
                <hr />
                {isMobile ? <iframe width="100%" height="960px" src="https://forms.office.com/r/pfrt0gBZKg?embed=true" frameBorder="0"> </iframe> : <iframe width="1300px" height="960px" src="https://forms.office.com/r/pfrt0gBZKg?embed=true" frameBorder="0"> </iframe>}
                {/* {isMobile ? <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfTEPSdOm1egrUFeYwWf5UOxyqf1zmyuspNuhwuKF2UpdrTNw/viewform?embedded=true" width="100%" height="1006" frameBorder="0">Loading…</iframe> :
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfTEPSdOm1egrUFeYwWf5UOxyqf1zmyuspNuhwuKF2UpdrTNw/viewform?embedded=true" width="640" height="1006" frameBorder="0">Loading…</iframe>} */}
            </div> : <></>}
            {this.state.page == Pages.Tables ? <div className='Page'>
                <hr />
                <div style={{ height: '100%', width: '100%', display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                    <DataGrid
                        sx={{fontSize: 20}}
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 }
                        },
                        filter: {
                            filterModel: {
                                items: []
                            }
                        },
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                showQuickFilter: true,
                            },
                        }}
                    />
                </div>
            </div> : <></>}
            
            </ThemeProvider>
        </div>
        )
    };
}

export default App;
